<template>
  <div>
    <el-row>
      <el-col :span="24" class="infoTit">
        <span
          v-if="goodsinfo.otcType === 1 && goodsinfo.drugCategory == 0"
          class="tag bgRed titColor radiusBox"
          >OTC</span
        >
        <span
          v-if="goodsinfo.otcType === 1 && goodsinfo.drugCategory == 1"
          class="tag bgGreen titColor radiusBox"
          >OTC</span
        >
        <span
          v-if="goodsinfo.otcType === 0"
          class="tag bgRed titColor radiusBox"
          >Rx</span
        >
        <span
          v-if="goodsinfo.newStatus == true || goodsinfo.newStatus == 'true'"
          class="tag bgGreen titColor"
          >新品</span
        >
        <span
          v-if="goodsinfo.medicalInsuranceType > 0"
          class="tag bgBlue titColor"
          >医保</span
        >
        <span v-if="goodsinfo.proprietary == 1" class="tag bgOrange titColor"
          >自营</span
        >
        <span class="brandName">{{ goodsinfo.brandName }} </span>

        <span v-if="goodsinfo.alias == '' || goodsinfo.alias == null"
          >{{ goodsinfo.name }}
          <em
            class="tag bgOrange titColor"
            style="width: auto; padding: 0 5px"
            >{{ arrGoods.name }}</em
          ></span
        >
        <span v-else>{{ goodsinfo.alias }} </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg">
        <span>{{ goodsinfo.subTitle }}</span>
        <!--   <p v-for="(item, index) in goodsinfo.subTitle.split('；')" :key="index" >{{ item }}</p> -->
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="countdownBox">
        <span class="countdownMsg">限时拼团</span>
        <span class="countdownDiv">
          <ProCountDown
            :startTime="startTime"
            :endTime="endTime"
          ></ProCountDown>
        </span>
      </el-col>
    </el-row>
  
    <el-row class="infoBox">
      <el-col :span="6">
        <span class="infoKey">拼团价：</span>
        <span class="price">
          <!--goodsinfo.price   -->
          <strong>￥</strong
          >{{ arrGoods.auditUserStatus===0 ? "会员可见" : FlashPrice }}
        </span>
      </el-col>
      
      <el-col :span="6">
        <span class="infoKey" style="font-size:12px"><del>{{arrGoods.auditUserStatus===0?"***":goodsinfo.originalPrice  | capitalize }}</del></span>
        
        <!-- <span class="infoKey">推荐零售价：</span>  -->
        <!-- <del>￥{{
            goodsinfo.originalPrice === "***"
              ? "会员可见"
              : goodsinfo.originalPrice
          }}</del> -->
      </el-col>
      <el-col :span="24" class="FlashBox">
        <el-col :span="6">
          <span>
            <em>已成团</em>{{ goodsinfo.perQuantity
            }}{{ goodsinfo.unit }}起拼</span
          >
        </el-col>
        <el-col :span="6">
          <span
            ><b>{{ goodsinfo.orderPromotionMsg.length }}</b
            >店参团</span
          >
        </el-col>
        <el-col :span="6">
          <span
            ><b>{{ goodsinfo.realQuantity }}</b>{{ goodsinfo.unit }}已付款</span>
        </el-col>
      </el-col>
      <el-row :span="24" class="DecVOBox" v-if="arrGoods.numPriceDetailVOList.length>0">
        <div class="DecVOLeft">
          <span><em>满降</em></span>
        </div>
        <div  class="DecVORight" >
          <p   v-for="(item, index) in arrGoods.numPriceDetailVOList" :key="index">满{{item.num}}盒享{{item.price}}元/盒</p>
        </div>
      </el-row>
      <el-col
        :span="24"
        class="infoActivity"
        v-for="(item, index) in promotionMap"
        :key="index"
      >
        <span class="infoKey" v-if="index == 0">促销活动:</span>
        <span class="infoKey" v-else></span>
        <span class="infoTag">{{ item.type }}</span>
        <span class="infoDes">{{ item.name }}</span>
      </el-col>
      <!-- <el-col :span="24" class="infoActivity">
        <span class="infoKey"></span>
        <span class="infoTag">免运费</span>
        <span class="infoDes">该商品免运费</span>
      </el-col> -->
    </el-row>
    <el-row :span="24" class="infoParameter">
      <div>
        <span class="infoKey">商品编号:</span
        ><span>{{ goodsinfo.productSn }}</span>
      </div>
      <div>
        <span class="infoKey">药品规格:</span
        ><span>{{ goodsinfo.specification }}</span>
      </div>
      <div>
        <span class="infoKey">件装规格:</span
        ><span>{{ goodsinfo.partSpecification }}</span>
      </div>
      <div>
        <span class="infoKey">效期优于:</span
        ><span>{{ goodsinfo.validDateFromat }}</span>
      </div>
      <div>
        <span class="infoKey" style="text-indent: 30px">库存:</span>
        <span
          v-if="goodsinfo.quantity == -1 ||goodsinfo.quantity - goodsinfo.realQuantity <Number(goodsinfo.minOrderNum)">
          备货中
        </span>
        <span v-if="goodsinfo.quantity - goodsinfo.realQuantity > 1000"
          >充足</span
        >
        <span
          v-if="
            goodsinfo.quantity - goodsinfo.realQuantity <= 1000 &&
            goodsinfo.quantity - goodsinfo.realQuantity >=
              Number(goodsinfo.minOrderNum)
          "
          >{{ goodsinfo.quantity - goodsinfo.realQuantity
          }}{{ goodsinfo.unit }}</span
        >
      </div>
      <div v-if="goodsinfo.quantity < 1 && goodsinfo.deliveryTimeFormat">
        <span class="infoKey">到货日期:</span
        ><span>{{ goodsinfo.deliveryTimeFormat }}</span>
      </div>
      <div @click="Download(goodsinfo.productId)">
        <span class="infoKey">首营资料：</span>
        <strong class="downBtn">查看/下载</strong>
      </div>
      <div v-if="arrGoods.countRestrict > 0">
        <span class="infoKey">单次购买限制:</span
        ><span>{{ arrGoods.countRestrict }}</span>
      </div>
      <div v-else>
        <span class="infoKey">单次购买限制:</span><span>无限制</span>
      </div>
    </el-row>

    <el-row class="infoNumBox">
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="goodsinfo.minOrderNum"
          :max="Number(goodsinfo.quantity)"
          :step="goodsinfo.minOrderQuantity"
          step-strictly
        ></el-input-number>
      </el-col>
    </el-row>
    <div class="infoBtns">
      <div :span="24">
        <span class="infoKey"></span>
        <button
          :disabled="
            goodsinfo.quantity - goodsinfo.realQuantity <
            Number(goodsinfo.minOrderNum)
          "
          :class="
            goodsinfo.quantity - goodsinfo.realQuantity <
            Number(goodsinfo.minOrderNum)
              ? 'grey shopBtn'
              : 'shopBtn'
          "
          @click="saveFun()"
        >
          立即参团
        </button>
      </div>
    </div>
    <!-- <el-col :span="24">
      <el-col :span="4"
        ><div class="grid-content bg-purple">
          服务承诺：<i class="el-icon-circle-check" aria-hidden="true"></i></div
      ></el-col>
      <el-col :span="4"
        ><div class="grid-content bg-purple-light">
          正品保障<i class="el-icon-circle-check" aria-hidden="true"></i></div
      ></el-col>
      <el-col :span="8"
        ><div class="grid-content bg-purple">
          满1000免运费【新疆除外】<i
            class="el-icon-circle-check"
            aria-hidden="true"
          ></i></div
      ></el-col>
      <el-col :span="4"
        ><div class="grid-content bg-purple-light">药监认证</div></el-col
      >
    </el-col> -->
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.info {
  overflow: hidden;
  .countdownBox {
    margin-bottom: -10px;
    background: linear-gradient(to right, #ec1042, #f7f4f4);
    height: 40px;
    line-height: 40px;
    .countdownMsg {
      padding-left: 20px;
      color: #000;
      font-size: 16px;
    }
    .countdownDiv {
      float: right;
      padding-right: 20px;
    }
  }
  .FlashBox {
    margin-bottom: 0 !important;
    .el-col {
      font-weight: bold;
      b {
        color: crimson;
      }
      em {
        border-radius: 5px;
        padding: 0 3px;
        background: #ff4d00;
        color: #000;
        margin-right: 10px;
      }
    }
  }
  .infoTit {
    font-size: 20px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    .brandName {
      font-weight: bolder;
      color: #333;
    }
    .tag {
      width: 42px;
      text-align: center;
      height: 20px;
      // padding: 0 5px;
      line-height: 19px;
      margin-top: 2px;
      border-width: 1px;
      font-size: 14px;
      border-style: solid;
      box-sizing: border-box;
      white-space: nowrap;
      display: inline-block;
      margin-right: 3px;
      &.titColor {
        color: #000;
      }
      &.radiusBox {
        border-radius: 50%;
      }
      &.bgRed {
        background-color: #dd1f1f;
        border-color: #dd1f1f;
      }
      &.bgBlue {
        background-color: #409eff;
        border-color: #409eff;
      }
      &.bgGreen {
        background-color: #52c41a;
        border-color: #52c41a;
      }
      &.bgOrange {
        background-color: #ff7a45;
        border-color: #ff7a45;
      }
    }
  }
  .infoMsg {
    color: #FFD303;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
    margin-top: 5px;
  }
  span.infoKey {
    width: 90px;
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
  .downBtn {
    cursor: pointer;
    &:hover {
      color: #FFD303;
    }
  }
  .infoNumBox {
    padding: 0px 10px;
    margin-bottom: 25px;
  }
  .infoBtns {
    padding: 0px 10px;
    z-index: 999;
    position: relative;
    margin-bottom: 20px;
    button {
      width: 150px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 0;
      padding: 0;
      &.addBtn {
        background: #FFD303;
        color: #000;
        margin-left: 20px;
        border: 1px solid #FFD303;
        outline: none;
      }
      &.shopBtn {
        background: #ff3333;
        color: #fff;
        border: 1px solid #ff3333;
        outline: none;
      }
      &.grey {
        background: #d4d2cf;
        color: #333;
        border: 1px solid #d4d2cf;
      }
    }
  }

  .infoParameter {
    padding: 0px 10px 25px;
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 20px;
    color: #333;
    div {
      float: left;
      width: 50%;
      margin-bottom: 15px;
      &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    // .el-col {
    //   margin-bottom: 15px;

    // }
  }
  .DecVOBox{
    margin-left:5px ;
    margin-bottom: 10px;
    .DecVOLeft{
      float: left;
      font-weight: bold;
      em {
        border-radius:2px;
        padding:2px 6px;
        font-size:15px;
        background: crimson;
        color: #000;
        margin-right: 10px;
      }
    }
    .DecVORight{
      float: left;
      p{
        margin-bottom: 5px;
        font-size:14px;
        color: crimson;
      }
    }
  }
  .infoBox {
    background: #f6f6f6;
    padding: 20px 10px 0 10px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top: 10px;
    .el-col {
      margin-bottom: 25px;
      &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 25px;
      }
    }

    span.price {
      color: #f43c38;
      font-size: 16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    span.infoTag {
      border: 1px solid #FFD303;
      color: #FFD303;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0 10px;
    }
    span.infoDes {
      color: #666;
    }
  }
}
</style>
<script>
const ProCountDown = () => import("components/product/ProCountDown.vue");
import { DownloadFirstTest } from "api/product.js";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "FlashGroupInfo",
  data() {
    return {
      num: 0,
      promotionMap: [],
      endTime: "",
      startTime: "",
      TimeBuyFlag: false,
      FlashPrice:0
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
    arrGoods: {
      type: Object,
    },
  },

  computed: {
    //计算属性可实时更新购物车数量
    CarGoodsNum() {
      return this.$store.getters.CarGoodsNum;
    },
    ...mapState({
      goodsnum: (state) => state.carGoodsNum,
    }),
  },
  methods: {
    handleClose(done) {
      done();
    },
    Download(goodsId) {
      // 显示loading
      this.$loading({ fullscreen: true, background: "#ffffff45" });
      //  alert(goodsId)
      DownloadFirstTest(goodsId).then((res) => {
        if (res.data.code == 400) {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
          // 关闭loading
          this.$loading().close();
        } else {
          let href = res.data.data;
          // 关闭loading
          this.$loading().close();
          window.open(href, "_blank", "toolbar=yes, width=900, height=700");
        }
      });
    },
    ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
    ...mapGetters({
      typeQuantity: "typeQuantity",
    }),
    handleChange(value) {
      // value
      this.arrGoods.numPriceDetailVOList.forEach(item=>{
        if(value>=item.num){
          // console.log(value)
          // console.log(item.num)
          // console.log(item.price)
          this.FlashPrice=item.price 
        }else{
          return false
        }
      }) 
    },

    // 单独成单，跳订单结算,query:{ids:moreIds}}
    saveFun: function () {
      let datailsArr = [
        {
          goodNum: this.goodsinfo.goodNum,
          goodsCount: this.num,
          goodsId: this.goodsinfo.productId,
          goodsType: 10,
          isRecentExpiration: this.goodsinfo.isRecentExpiration,
          // originalPrice: this.goodsinfo.price,
          originalPrice: this.FlashPrice,
          promotionId: this.arrGoods.id,
        },
      ];

      let datas = {
        details: datailsArr,
        purchaseNum: this.num, //购买商品数量
        orderType: 9,
        promotionId: this.arrGoods.id,
      };
      sessionStorage.setItem("FlashGroup", JSON.stringify(datas));
      // 单独成单，跳订单结算,query:{ids:moreIds}}
      this.$router.push({ name: "GoodsAmount", query: { id: "FlashGroup" } });
    },
  },
  mounted() {},
  components: {
    ProCountDown,
  },
  watch: {
    goodsinfo: function () {},
  },
  created() {
    this.num = this.goodsinfo.minOrderNum;
    console.log("this.goodsinfo", this.goodsinfo);
    console.log("this.arrGoods", this.arrGoods);
    this.endTime = this.arrGoods.endDate;
    this.startTime = this.arrGoods.beginDate;

    this.FlashPrice=this.goodsinfo.price
    // this.promotionMap=this.goodsinfo.promotionMap
  },
};
</script>
